import { Component, NgModule, OnInit } from '@angular/core';
@NgModule()
@Component({
  selector: 'app-request-clip',
  templateUrl: './request-clip.component.html',
  styleUrls: ['./request-clip.component.scss'],
})
export class RequestClipComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
