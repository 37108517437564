import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController, NavController, LoadingController, ToastController } from '@ionic/angular';
import { Global } from '../global';
import { ServerService } from '../server.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { AutoCompleteService } from 'ionic4-auto-complete';

declare var google;

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.page.html',
  styleUrls: ['./add-location.page.scss'],
})
export class AddLocationPage implements OnInit {
  addForm: FormGroup;
  submitAttempt: boolean = false;
  map;
  longitude: any;
  latitude: any;
  address: any;
  autocompleteItems;
  autocomplete;
  service = new google.maps.places.AutocompleteService();
  geo: any;
  temp: boolean = true;

  constructor(
    public navCtrl: NavController,
    public global: Global,
    public server: ServerService,
    public formBuilder: FormBuilder,
    public loadingCtrl: LoadingController,
    public toastController: ToastController,
    public modalCtrl: ModalController,
    public zone: NgZone,

  ) {
    this.autocompleteItems = [];
    this.autocomplete = {
      query: ''
    };
    this.addForm = formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      address: [],
      latitude: [],
      longitude: [],
      radius: ['', Validators.compose([Validators.required])],
      monitoringStatus: ['', Validators.compose([Validators.required])],
    })

  }

  ngOnInit() {
    console.log("user address in global", this.global.user_address)
    this.loadMap();
  }

  modelclose() {
    this.modalCtrl.dismiss();
  }

  dismiss() {
    this.temp = false;
    this.autocompleteItems = [];
  }

  chooseItem(item: any) {
    this.geo = item;
    this.geoCode(this.geo);
    console.log("item", item)
    console.log("geo", this.geo)
    this.temp = false;
  }

  updateSearch() {
    console.log("search is on")
    console.log(this.autocompleteItems);
    console.log("length", this.autocomplete.query.length);

    if (this.autocomplete.query == '') {
      console.log("if run")
      this.autocompleteItems = [];
      this.temp = false;
      return;
    }
    else if (this.autocomplete.query.length >= 3) {
      console.log("else if run")
      this.temp = true;
      let me = this;
      this.service.getPlacePredictions({
        input: this.autocomplete.query,
        componentRestrictions: {
          country: 'us'
        }
      }, (predictions, status) => {
        me.autocompleteItems = [];

        me.zone.run(() => {
          if (predictions != null) {
            predictions.forEach((prediction) => {
              me.autocompleteItems.push(prediction.description);
              console.log(me.autocompleteItems);
            });
          }
        });
      });
    }
    else {
      console.log("else run")
    }
  }



  geoCode(address: any) {
    let that = this;
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
      console.log("search address lati", this.latitude);
      console.log("search address longi", this.longitude);
      that.global.user_latitude = this.latitude;
      that.global.user_longitude = this.longitude;

      console.log("search global long", that.global.user_longitude);
      console.log("search global lati", that.global.user_latitude);
      this.loadMap();
    });
  }

  loadMap() {

    // console.log("user lati.....", this.global.user_latitude);
    // console.log("user longi......", this.global.user_longitude);
    // var location;
    // if (this.global.mainAddress != undefined) {
    //   location = [this.global.mainAddress.coordinates.lat, this.global.mainAddress.coordinates.lng];
    //   console.log("main location", this.global.mainAddress)
    // }
    // else {
    //   location = [this.global.user_latitude, this.global.user_longitude];
      
    // }


    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 10,
      center: { lat: this.global.userAddress.coordinates.lat, lng: this.global.userAddress.coordinates.lng}
    });
    var marker = new google.maps.Marker({
      position: { lat: this.global.userAddress.coordinates.lat, lng: this.global.userAddress.coordinates.lng},
      map: map,
      title: '',
      draggable: true,
      icon: { url: 'assets/bus-stop.png' },
      animation: google.maps.Animation.DROP,
    });

  new google.maps.Marker(
    {
      position: { lat: this.global.mainAddress.coordinates.lat, lng: this.global.mainAddress.coordinates.lng},
      map: map,
      icon: { url: 'assets/school.png' },
      title: this.global.mainAddress.name,
      draggable: false,
      animation: google.maps.Animation.DROP,
    });




    this.longitude = this.global.user_longitude;
    this.latitude = this.global.user_latitude;
    this.address = this.geo;
    console.log("geo address", this.address)
    let content = this.address;
    this.addInfoWindow(marker, content);
  }


  addInfoWindow(marker, content) {
    var that = this;
    let infoWindow = new google.maps.InfoWindow({
      content: content,
    });
    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.open(this.map, marker);
    });
    google.maps.event.addListener(marker, 'dragend', function () {
      this.markerlatlong = marker.getPosition();

      this.longitude = marker.getPosition().lng();
      this.latitude = marker.getPosition().lat();
      that.latitude = this.latitude;
      that.longitude = this.longitude;

      console.log("longitude", this.longitude)
      console.log("latitude", this.latitude)

      var geocoder = new google.maps.Geocoder;
      let latlng = { lat: this.latitude, lng: this.longitude };

      geocoder.geocode({ 'location': latlng }, (results, status) => {
        console.log("result_address", results);
        console.log(status);
        this.address = results[0].formatted_address;
        console.log("address", this.address)
        that.address = this.address;
        console.log("global address", that.address)
      });
    });
  }


  async Addlocation() {

    this.submitAttempt = true;

    this.addForm.value.latitude = this.latitude;
    this.addForm.value.longitude = this.longitude;
    this.addForm.value.address = this.address;

    console.log(this.addForm.value);

    if (!this.addForm.valid) {
      this.global.presentToast("please fill name & radius");
      console.log(' Some values were not given or were incorrect, please fill them');
    } else {

      let response = this.server.add_location(this.addForm.value, this.geo);
      const loading = await this.loadingCtrl.create({
        message: 'Please wait',
      });
      await loading.present();

      response.subscribe(res => {
        console.log("response", res);
        console.log("data", res.data);
        loading.dismiss();

        if (res.data.success === true) {

          this.global.presentToast(res.data.message);
          this.modalCtrl.dismiss();
        }
        else {
          console.log('success false');
          this.global.presentAlert('Error!', res.data.message, 'OK');
        }

      }, error => {
        console.log("Error occured", error);
        loading.dismiss();
        this.global.alertMsg("Failure", "Something went wrong. Please check your connection");
      });
    }
  }


}